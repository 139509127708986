import Layout from "../../components/Layout";
import EntityPageComponent from "./page";

const EntityPage = () => {
  return (
    <Layout>
      <EntityPageComponent />
    </Layout>
  );
};

export default EntityPage;
