import { Link } from "react-router-dom";

const NotFoundPageComponent = () => {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center gap-4">
      <h1 className="text-2xl">Faqja nuk u gjet</h1>
      <Link to="/" className="text-amber-700">
        Shko në faqen kryesore
      </Link>
    </div>
  );
};

export default NotFoundPageComponent;
