import React, { useEffect, useState } from "react";
import { GET_BILLS_QUERY, GET_BILL_BY_ID_QUERY } from "../graphql/Queries";
import { client } from "../graphql/apollo-client";
import { CheckSquare, Pencil, XCircle } from "lucide-react";
import { Input } from "./ui/input";
import { useMutation } from "@apollo/client";
import { EDIT_BILL_TAXES } from "../graphql/Mutations";
import { toast } from "./ui/use-toast";
import { Button } from "./ui/button";

interface DataTypes {
  taxesList: { taxName: string; valueString: string }[];
}
const EditBill = (props: any) => {
  const { bill, data, setData } = props;
  const [editingTaxIndex, setEditingTaxIndex] = useState<number | null>(null);
  const [editedValue, setEditedValue] = useState<string>("");

  const handleEditClick = (index: number, currentValue: string) => {
    if (editingTaxIndex === index) {
      setEditingTaxIndex(null);
    } else {
      setEditingTaxIndex(index);
      setEditedValue(currentValue);
    }
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedValue(event.target.value);
  };
  const handleSave = (taxName: string) => {
    if (data) {
      const updatedData = { ...data };
      const updatedTaxesList = updatedData.taxesList.map((tax: any) => ({
        taxName: tax.taxName,
        valueString: tax.valueString,
        value: 0,
      }));
      const updatedTaxes = updatedTaxesList.map((tax: any) =>
        tax.taxName === taxName ? { ...tax, valueString: editedValue } : tax
      );
      setData({ ...data, taxesList: updatedTaxes });
    }
  };

  const [editBill] = useMutation(EDIT_BILL_TAXES, {
    fetchPolicy: "no-cache",
    variables: {
      billId: bill,
      taxes: data?.taxesList,
    },
    refetchQueries: [
      {
        query: GET_BILL_BY_ID_QUERY,
        variables: {
          billId: bill,
        },
      },
    ],

    onCompleted: () => {
      toast({
        title: "Taksat u ndryshuan me sukses",
        description: "Tarifat e taksave u përditësuan me sukses!",
        duration: 3000
      });
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        title: "Problem në ndryshimin e taksave",
        description: `Taksa nuk u përditësua`,
        duration: 3000
      });
    },
  });
  return (
    <div className="flex flex-col mt-4">
      {data &&
        data?.taxesList.map((tax: any, index: any) => (
          <div
            key={tax.taxName.replaceAll(" ", "_").toUpperCase()}
            className={`w-full my-0.5 flex items-center justify-between ${
              index % 2 === 0 ? "bg-[#DEDEDE]" : ""
            }`}
          >
            <p className="text-left">
              {index + 1}. {tax?.taxName}
            </p>
            <div className="flex gap-2 mr-0.5 items-center justify-center">
              {editingTaxIndex === index ? (
                <>
                  <Input
                    type="text"
                    defaultValue={editedValue}
                    onChange={handleValueChange}
                    onBlur={() => handleSave(tax.taxName)}
                    className={`text-center my-1 ${
                      index % 2 === 0 ? "bg-transparent border-white" : ""
                    }`}
                  />
                  <p>Lekë</p>
                  <Button
                    onClick={() => {
                      editBill();
                      handleEditClick(index, tax.valueString);
                    }}
                    className="gap-2 p-2 bg-emerald-600 hover:bg-emerald-700"
                  >
                    <CheckSquare className="h-5 w-5 cursor-pointer" />
                    Ndrysho
                  </Button>
                  <Button
                    onClick={() => handleEditClick(index, tax.valueString)}
                    className="gap-2 p-2 bg-red-600 hover:bg-red-700"
                  >
                    <XCircle className="h-5 w-5 cursor-pointer" /> Anullo
                  </Button>
                </>
              ) : (
                <>
                  <p className="text-right">{tax.valueString} Lekë</p>
                  <Pencil
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => handleEditClick(index, tax.valueString)}
                  />
                </>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default EditBill;
