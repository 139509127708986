import { useEffect } from "react";
import {
  GET_CATEGORIES_QUERY,
  GET_SUBJECTS_QUERY,
  GET_TAXES_QUERY,
} from "../../graphql/Queries";
import { EntitiesTable } from "./data-table";
import { useQuery } from "@apollo/client";

const EntitiesPageComponent = () => {
  const {
    data: subjectsData,
    loading: subjectsDataLoading,
    error: subjectsDataError,
  } = useQuery(GET_SUBJECTS_QUERY, {
    fetchPolicy: "no-cache",
  });
  const {
    data: taxesData,
    loading: taxesDataLoading,
    error: taxesDataError,
  } = useQuery(GET_TAXES_QUERY);
  const {
    data: categoriesData,
    loading: categoriesDataLoading,
    error: categoriesDataError,
  } = useQuery(GET_CATEGORIES_QUERY);

  return (
    <div>
      <EntitiesTable
        subjectsData={subjectsData}
        subjectsDataLoading={subjectsDataLoading}
        subjectsDataError={subjectsDataError}
        taxesData={taxesData}
        categoriesData={categoriesData}
      />
    </div>
  );
};

export default EntitiesPageComponent;
