import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, ChevronLeft } from "lucide-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { cn } from "../../lib/utils";
import moment from "moment";

export type Entity = {
  comment: string;
  from: "AKTIV" | "PASIV" | "PEZULLUAR";
  to: "AKTIV" | "PASIV" | "PEZULLUAR";
  createdOnUtc: string;
};

export const columns: ColumnDef<Entity>[] = [
  {
    accessorKey: "from",
    header: "Statusi i mëparshëm",
    cell: ({ row }) => (
      <div
        className={cn(
          "px-3.5 py-2 capitalize rounded-lg my-2 text-center max-w-xs",
          row.getValue("from") === "AKTIV"
            ? "bg-green-200 text-green-950 font-semibold"
            : row.getValue("from") === "PASIV"
            ? "bg-red-200 text-red-950 font-semibold"
            : "bg-orange-200 text-orange-900 font-semibold"
        )}
      >
        {row.getValue("from") === "AKTIV"
          ? "Aktiv"
          : row.getValue("from") === "PASIV"
          ? "Pasiv"
          : "Pezulluar"}
      </div>
    ),
  },
  {
    accessorKey: "to",
    header: "Statusi i  ndryshuar",
    cell: ({ row }) => (
      <div
        className={cn(
          "px-3.5 py-2 capitalize rounded-lg my-2 text-center max-w-xs",
          row.getValue("to") === "AKTIV"
            ? "bg-green-200 text-green-950 font-semibold"
            : row.getValue("to") === "PASIV"
            ? "bg-red-200 text-red-950 font-semibold"
            : "bg-orange-200 text-orange-900 font-semibold"
        )}
      >
        {row.getValue("to") === "AKTIV"
          ? "Aktiv"
          : row.getValue("to") === "PASIV"
          ? "Pasiv"
          : "Pezulluar"}
      </div>
    ),
  },
  {
    accessorKey: "createdOnUtc",
    header: "Data",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {moment(row.getValue("createdOnUtc")).format("MM.DD.YYYY")}
      </div>
    ),
  },
  {
    accessorKey: "comment",
    header: "Komenti",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 rounded-lg my-2 text-center">
        {row.getValue("comment")}
      </div>
    ),
  },
];

export function EntityStatusHistory(props: any) {
  const { entityData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data: entityData?.companyById?.comments,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="w-full flex flex-row items-center justify-between px-2">
          <p className="font-medium text-lg">
            Historiku i statusit të subjektit
          </p>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Filtro të dhënat <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {String(column.columnDef?.header)}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border w-full max-w-[calc(100vw-32px)]">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {entityData?.companyById?.comments.length ? (
              table.getRowModel().rows.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  Asnjë rezultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {entityData?.companyById?.comments?.length ? (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows?.length} nga{" "}
            {table.getFilteredRowModel().rows?.length} rrjeshta zgjedhur.
            {table.getFilteredSelectedRowModel().rows?.length} nga{" "}
            {table.getFilteredRowModel().rows?.length} rrjeshta zgjedhur.
          </div>
          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table?.previousPage()}
              disabled={!table?.getCanPreviousPage()}
            >
              Mbrapa
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table?.nextPage()}
              disabled={!table?.getCanNextPage()}
            >
              Përpara
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
