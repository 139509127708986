import { useCallback, useState } from "react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import useUser from "../../hooks/useUser";
import { LockIcon } from "lucide-react";

type LoginData = {
  email?: string;
  password?: string;
} | null;

const AuthPageComponent = () => {
  const { loginLoading, logInUser } = useUser();

  const [loginData, setLoginData] = useState<LoginData>(null);

  const handleInputFieldChange = useCallback(function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    setLoginData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  },
  []);

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <form
        onSubmit={(e) => e.preventDefault()}
        className="max-w-sm w-full flex flex-col gap-4 justify-center items-center"
      >
        <div className="rounded flex justify-center items-center">
          <h1 className="text-xl text-amber-950 font-bold whitespace-nowrap">
            🪙 COIN{" "}
            <span className="bg-amber-300 px-[5px] rounded-md">MASTER</span>
          </h1>
        </div>
        <Input
          name="email"
          type="text"
          placeholder="Vendos email-in ose emrin e përdoruesit"
          onChange={handleInputFieldChange}
        />
        <Input
          name="password"
          type="password"
          placeholder="Vendos fjalëkalimin"
          onChange={handleInputFieldChange}
        />
        <Button
          type="submit"
          className="w-full bg-amber-300 hover:bg-amber-200 text-amber-950 font-bold gap-1"
          onClick={() => logInUser && logInUser(loginData)}
        >
          {loginLoading ? (
            <div
              className="inline-block h-4 w-4 animate-spin rounded-full border border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          ) : (
            <>
              <LockIcon className="w-4 h-4" />
              Kyçu
            </>
          )}
        </Button>
      </form>
    </div>
  );
};

export default AuthPageComponent;
