import { useMutation } from "@apollo/client";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown } from "lucide-react";
import * as React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { toast } from "../../components/ui/use-toast";
import { CREATE_TAX } from "../../graphql/Mutations";
import { GET_TAXES_QUERY } from "../../graphql/Queries";

export type Entity = {
  name: string;
  value: number;
  category: string;
};

export const columns: ColumnDef<Entity>[] = [
  {
    accessorKey: "name",
    header: "Emri",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("name") === "FINANCE" ? "Financë" : row.getValue("name")}
      </div>
    ),
  },
  {
    accessorKey: "value",
    header: "Vlera",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("value")}
      </div>
    ),
  },
];

export function TaxesEntitiesTable(props: any) {
  const { taxesData, taxesDataLoading, taxesDataError } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  // const [category, setCategory] = React.useState("FIXED")

  const [userFormData, setUserFormData] = React.useState({
    name: "",
    value: 0,
  });

  const handleAddData = (e: any) => {
    setUserFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Mutations
  const [createTax] = useMutation(CREATE_TAX);

  const handleCreateTax = () => {
    createTax({
      variables: {
        category: "FIXED",
        name: userFormData?.name,
        value: Number(userFormData?.value),
      },
      refetchQueries: [GET_TAXES_QUERY],
      onCompleted: () => {
        toast({
          title: "Taksa u shtua me sukses",
          description: "Të dhënat e futura për taksën u shtuan me sukses",
          duration: 3000,
        });
      },
      onError: (err) => {
        toast({
          variant: "destructive",
          title: "Taksa Nuk mund të shtohet",
          description: `Problem në shtimin e taksës`,
          duration: 3000,
        });
      },
    });
  };

  const taxesTable = useReactTable({
    data: taxesData?.taxes,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (taxesDataLoading) {
    return <div>Loading...</div>;
  }

  if (taxesDataError) {
    return <div>Error loading data</div>;
  }
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Kërko..."
          value={
            (taxesTable.getColumn("name")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            taxesTable.getColumn("name")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <div className="ml-auto">
          <AlertDialog>
            {/* <AlertDialogTrigger asChild>
              <Button className="m-auto mx-3" variant="outline">
                Shto taksë
              </Button>
            </AlertDialogTrigger> */}
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="text-center">
                  Regjistro taksë!
                </AlertDialogTitle>
                <AlertDialogDescription>
                  <form action="" className="w-full flex flex-col gap-y-4">
                    <div className="flex flex-col gap-2">
                      <Label htmlFor="name">Emri i taksës:</Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        onChange={handleAddData}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label htmlFor="value">Vlera e taksës:</Label>
                      <Input
                        type="number"
                        min={0}
                        id="value"
                        name="value"
                        onChange={handleAddData}
                        required
                      />
                    </div>
                    {/* <div className="flex flex-col gap-2">
                      <Label htmlFor="category" className="mb-3">
                        Kategoria e taksës:
                      </Label>
                      <RadioGroup className="flex flex-row items-center border border-gray-200 rounded-lg px-2" name="category">
                        <div className="flex items-center w-full gap-1">
                          <RadioGroupItem
                            value="FIXED"
                            id="r1"
                            checked={category === "FIXED"}
                            onClick={(e: any) => setCategory(e.target.value)}
                          />
                          <Label
                            htmlFor="r1"
                            className="py-3 w-full text-amber-950"
                          >
                            Fikse
                          </Label>
                        </div>
                        <div className="flex items-center w-full gap-1">
                          <RadioGroupItem
                            value="CHANGE"
                            id="r2"
                            checked={category === "CHANGE"}
                            onClick={(e: any) => setCategory(e.target.value)}
                          />
                          <Label
                            htmlFor="r2"
                            className="py-3 w-full text-amber-950"
                          >
                            E ndryshueshme
                          </Label>
                        </div>
                      </RadioGroup>
                    </div> */}
                  </form>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Anullo</AlertDialogCancel>
                <AlertDialogAction
                  className="bg-amber-200 text-amber-950 hover:bg-amber-100"
                  onClick={handleCreateTax}
                >
                  Shto Taksën
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Filtro të dhënat <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {taxesTable
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {String(column.columnDef?.header)}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="rounded-md border w-full max-w-[calc(100vw-32px)]">
        <Table>
          <TableHeader>
            {taxesTable.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {taxesData?.taxes?.length ? (
              taxesTable.getRowModel().rows.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  Asnjë rezultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {taxesTable.getFilteredSelectedRowModel().rows?.length} nga{" "}
          {taxesTable.getFilteredRowModel().rows?.length} rrjeshta zgjedhur.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => taxesTable.previousPage()}
            disabled={!taxesTable.getCanPreviousPage()}
          >
            Mbrapa
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => taxesTable.nextPage()}
            disabled={!taxesTable.getCanNextPage()}
          >
            Përpara
          </Button>
        </div>
      </div>
    </div>
  );
}
