import { useQuery } from "@apollo/client";
import { BarChart } from "@tremor/react";
import { ComponentType, useCallback } from "react";
import { Card } from "../../components/ui/card";
import { DASHBOARD_DATA } from "../../graphql/Queries";

const Months = [
  {
    key: "Jan",
    value: "Janar",
  },
  {
    key: "Feb",
    value: "Shkurt",
  },
  {
    key: "Mar",
    value: "Mars",
  },
  {
    key: "Apr",
    value: "Prill",
  },
  {
    key: "May",
    value: "Maj",
  },
  {
    key: "Jun",
    value: "Qershor",
  },
  {
    key: "Jul",
    value: "Korrik",
  },
  {
    key: "Aug",
    value: "Gusht",
  },
  {
    key: "Sep",
    value: "Shtator",
  },
  {
    key: "Oct",
    value: "Tetor",
  },
  {
    key: "Nov",
    value: "Nëntor",
  },
  {
    key: "Dec",
    value: "Dhjetor",
  },
];

const valueFormatter = (number: number) =>
  `${new Intl.NumberFormat("us").format(number).toString()} Lekë`;

const tooltip: ComponentType<any> = ({ payload, active }) => {
  if (!active || !payload) return null;
  return (
    <div className="w-56 bg-white rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      {payload?.map((category: any, idx: number) => (
        <div key={idx} className="flex flex-1 space-x-2.5">
          <div
            className={`w-1 flex flex-col bg-${category.color}-500 rounded`}
          />
          <div className="space-y-1">
            <p className="text-tremor-content">{category.dataKey}</p>
            <p className="font-medium text-tremor-content-emphasis">
              {category?.value} Lekë
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const DashboardPageComponent = () => {
  const { data } = useQuery(DASHBOARD_DATA, {
    fetchPolicy: "cache-and-network",
  });

  const getNameOfMothByKey = useCallback((key: string) => {
    const monthName = Months.filter((month: any, idx) => month.key === key);
    return monthName[0].value;
  }, []);

  return (
    <div className="w-full">
      <h1 className="text-4xl font-bold text-amber-950">Paneli qendror</h1>
      {data?.dashboard && (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-8 gap-4">
          <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
            <h6 className="text-sm font-medium text-amber-950">
              Të ardhurat totale
            </h6>
            <p className="font-bold text-2xl text-amber-950">
              {data?.dashboard[0].value} Lekë
            </p>
            {/* <p className="text-sm text-amber-950">+20% nga muaji i fundit</p> */}
          </Card>
          <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
            <h6 className="text-sm font-medium text-amber-950">
              Të ardhurat këtë muaj
            </h6>
            <p className="font-bold text-2xl text-amber-950">
              {data?.dashboard[1].value} Lekë
            </p>
            {/* <p className="text-sm text-amber-950">90% e muajit të fundit</p> */}
          </Card>
          <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
            <h6 className="text-sm font-medium text-amber-950">
              Subjektet që kanë kryer pagesat këtë muaj{" "}
            </h6>
            <p className="font-bold text-2xl text-amber-950">
              {data?.dashboard[2].value} nga {data?.dashboard[3].value}{" "}
              <span className="text-base">subjekte aktive</span>
            </p>
            {/* <p className="text-base text-amber-950">
              485/500 (97%) në muajin e fundit
            </p> */}
          </Card>
          <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
            <h6 className="text-sm font-medium text-amber-950">
              Subjekte aktive
            </h6>
            <p className="font-bold text-2xl text-amber-950">
              {data?.dashboard[3].value}
            </p>
            {/* <p className="text-sm text-amber-950">700 subjekte në total</p> */}
          </Card>
        </div>
      )}
      {data?.annualRevenues && (
        <div className="mt-8 w-full max-w-full">
          <Card className="p-6 w-full bg-white border-amber-300">
            <h6 className="text-base font-medium text-amber-950">
              Të ardhurat sipas muajve
            </h6>
            <BarChart
              className="mt-6 w-full"
              data={data?.annualRevenues.map((item: any, idx: number) => {
                return {
                  name: getNameOfMothByKey(item.key),
                  "Të ardhurat": item.value,
                };
              })}
              customTooltip={tooltip}
              index="name"
              categories={["Të ardhurat"]}
              colors={["amber"]}
              valueFormatter={valueFormatter}
              yAxisWidth={48}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default DashboardPageComponent;
